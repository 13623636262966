import _typeof2 from "./typeof.js";
import _assertThisInitialized from "./assertThisInitialized.js";
var exports = {};
var _typeof = _typeof2["default"];
var assertThisInitialized = _assertThisInitialized;

function _possibleConstructorReturn(self, call) {
  if (call && (_typeof(call) === "object" || typeof call === "function")) {
    return call;
  } else if (call !== void 0) {
    throw new TypeError("Derived constructors may only return object or undefined");
  }

  return assertThisInitialized(self);
}

exports = _possibleConstructorReturn, exports.__esModule = true, exports["default"] = exports;
export default exports;
export const __esModule = exports.__esModule;